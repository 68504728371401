@media (max-width: 480px) {
  .nav-menu {
    font-size: 16px;
    padding: 15px 5px;
  }

  .section {
    width: 300px;
    line-height: 30px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .status-item {
    font-size: 13px;
    padding: 5px;
  }

  .not-found {
    font-size: 25px;
  }
}
